import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { signUp } from "aws-amplify/auth";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Typography,
  Link,
  Checkbox,
  Grid,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import FormField from "../../assets/theme/overrides/FormField";
import { PrimaryButton } from "../../assets/theme/overrides/button";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one numeric digit."
    ),
});

const initialValues = {
  firstName: "",
  email: "",
  password: "",
};

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "20px",
  padding: "30px 20px",
  width: "100%",
  maxWidth: "500px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "260px",
    padding: "24px 12px",
  },
}));

export default function Signup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    // console.log("values", values);
    try {
      setIsLoading(true);
      const data = await signUp({
        username: values.email,
        password: values.password,
        options: {
          userAttributes: {
            email: values.email,
            given_name: values.firstName,
          },
          autoSignIn: true,
        },
      });
      if (data) {
        setIsLoading(false);
        toast.success(
          "Please verify your self first, we have sent the verification link on your email"
        );
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
        // navigate("/verification", {
        //   state: { email: values.email, password: values.password },
        // });
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("signup error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Item elevation={4}>
          <Box>
            <Typography
              variant="h3"
              sx={{ textAlign: "center" }}
              color="primary"
            >
              Sign up
            </Typography>
          </Box>
          <Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <FormField
                  name="firstName"
                  label="First Name"
                  type="firstName"
                />
                <FormField name="email" label="Email" type="email" />
                <FormField name="password" label="Password" type="password" />
                <Box display="flex" mt={3} mb={3}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <p style={{ textAlign: "left" }}>
                        {`I agree to the `}
                        <Link
                          href="https://www.hellopraxis.com/privacy"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "20px",
                    margin: "30px 0",
                  }}
                >
                  <PrimaryButton variant="contained" type="submit">
                    {isLoading ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      "Sign Up"
                    )}
                  </PrimaryButton>
                </Box>
              </Form>
            </Formik>
            <Box>
              <Box mb={3}>
                <Link
                  underline="none"
                  onClick={() => navigate("/forgot-password")}
                  sx={{
                    textAlign: "end",
                    cursor: "pointer",
                  }}
                >
                  Forgot password?
                </Link>
              </Box>
              <Typography sx={{ textAlign: "center" }}>
                Already have an account?{" "}
                <Link
                  underline="none"
                  onClick={() => navigate("/signin")}
                  sx={{
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Sign In here
                </Link>
              </Typography>
            </Box>
          </Box>
        </Item>
      </Grid>
    </>
  );
}
