import React from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "../redux/reducer";
import Header from "../Components/Header";
import { Box } from "@mui/material";

const PrivateContainer = ({ children, tabTitle, isDashboard }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const checkAuth = async () => {
    try {
      const data = await fetchUserAttributes();
      if (data) {
        dispatch(setUser(data));
        return;
      } else {
        return navigate("/signin");
      }
    } catch {
      return navigate("/");
    }
  };

  useEffect(() => {
    if (tabTitle) document.title = tabTitle;
  }, [tabTitle]);

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        overflow: isDashboard ? "hidden" : "auto",
        position: "relative",
        background: "#FDFDFD",
      }}
    >
      <Header menu={user ? false : true} />
      {children}
    </Box>
  );
};

export default PrivateContainer;
