import React, { useState, useRef, useEffect, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import MicIcon from "@mui/icons-material/Mic";
import { IconButton } from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Box } from "@mui/material";
import { RealtimeClient } from "@openai/realtime-api-beta";
import { useSelector } from "react-redux";
import axios from "axios";
import { KeepAwake } from "@capacitor-community/keep-awake";
import { WavStreamPlayer } from "../utils/wavtools/wav_stream_player";
import { WavRecorder } from "../utils/wavtools/wav_recorder";

const keepAwake = async () => {
  const result = await KeepAwake.isSupported();
  if (result.isSupported) {
    await KeepAwake.keepAwake();
  }
};

const allowSleep = async () => {
  await KeepAwake.allowSleep();
};

const VoiceRecorder = ({ isVoiceAgent, setIsVoiceAgent, myFiles }) => {
  const [isRecording, setIsRecording] = useState(true);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  // const agentList = useSelector((state) => state.agentList);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const user = useSelector((state) => state.user);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const flowPipeline = useSelector((state) => state.flowPipeline);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);

  const wavRecorderRef = useRef(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef(
    new RealtimeClient({
      url: process.env.REACT_APP_REALTIME_API,
    })
  );

  const startRecording = useCallback(async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    if (client.isConnected()) {
      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: `Hello`,
        },
      ]);
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    let instructions = "";

    keepAwake();

    if (
      myFiles.length > 0 ||
      !["default", "meetingAssistant", "UX"].includes(typeOfAg)
    ) {
      instructions = `You are a helpful assistant.
      For all query or question, always use the 'search' tool.
      Assume the user has already provided a document. For any document-related question, proceed directly to use the search tool on the document to retrieve information. Avoid requesting the user to re-upload or confirm the document's presence.
      The user is listening to answers with audio, so please make sure to respond with a helpful voice via audio.`;
      client.addTool(
        {
          name: "search",
          description: `For all query or question.`,
          parameters: {
            type: "object",
            properties: {
              query: {
                type: "string",
                description:
                  "Users, as it is a full search query. Do not modify or interpret the original query.",
              },
            },
            required: ["query"],
          },
        },
        async ({ query }) => {
          const formData = new FormData();
          formData.append("query", query);
          formData.append("AImodel", selectedAIModel);
          formData.append("vectorTypeDB", vectorTypeDB);
          formData.append("userId", user.sub);
          formData.append("email", user.email);
          formData.append("temperature", temperature);
          formData.append("isRFormate", isRFormate);
          formData.append("typeOfAg", typeOfAg);
          formData.append("pipelineTypeDoc", pipelineTypeDoc);
          formData.append("flowPipeline", flowPipeline);
          formData.append("isFile", myFiles.length > 0);
          formData.append("conversation", JSON.stringify([]));
          const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/chat`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          const output = data?.response;
          return output;
        }
      );
    } else {
      instructions = `System settings:
      Tool use: enabled.
      
      Instructions:
      - You are an artificial intelligence agent responsible for helping realtime voice capabilities
      - Please make sure to respond with a helpful voice via audio
      - Be kind, helpful, and curteous
      - It is okay to ask the user questions
      - Use tools and functions you have available liberally, it is part of the training apparatus
      - Be open to exploration and conversation
      - Response must in english language only.
      - Always use the 'tavily_search' tool to Get information on recent events from the web.
      
      Personality:
      - Be upbeat and genuine
      - Try speaking quickly as if excited`;
      client.addTool(
        {
          name: "tavily_search",
          description: "Get information on recent events from the web.",
          parameters: {
            type: "object",
            properties: {
              query: {
                type: "string",
                description:
                  "The search query to use. For example: 'Latest news on Nvidia stock performance'.",
              },
            },
            required: ["query"],
          },
        },
        async ({ query }) => {
          const { data } = await axios.post(`https://api.tavily.com/search`, {
            api_key: "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",
            query: query,
          });
          const output = data.results;
          return output;
        }
      );
    }

    client.updateSession({
      instructions: instructions, // Set instructions
      input_audio_transcription: { model: "whisper-1" },
      turn_detection: { type: "server_vad" },
    });

    // client.on("realtime.event", (realtimeEvent) => {
    //   console.log(realtimeEvent.event.type);
    // });

    client.on("error", (event) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on("conversation.updated", async ({ item, delta }) => {
      // const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
    });

    (async () => {
      await client.connect(); // Connect to realtime API
      await startRecording();
    })();

    return () => {
      allowSleep();
      client.reset(); // cleanup; resets to defaults
    };
  }, []);

  const disconnectConversation = useCallback(async () => {
    const wavRecorder = wavRecorderRef.current;
    if (wavRecorder.getStatus() !== "ended") {
      await wavRecorder.end();
    }

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const handleClose = () => {
    setIsVoiceAgent(false);
    setIsRecording(false);
    disconnectConversation();
  };

  return (
    <>
      <Dialog onClose={handleClose} open={isVoiceAgent} fullWidth maxWidth="sm">
        {/* <DialogTitle>Speech to speech agent</DialogTitle> */}
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AudioRipple isRecording={isRecording} />

          <IconButton onClick={handleClose}>
            <StopCircleIcon sx={{ fontSize: "60px" }} />
          </IconButton>
        </Box>
      </Dialog>
    </>
  );
};

export default VoiceRecorder;

const AudioRipple = ({ isRecording }) => {
  return (
    <Box sx={{ my: 5 }}>
      <Box className={`ripple-container`}>
        <Box className={`ripple ${isRecording && "animate"}`}></Box>
        <Box className={`ripple ${isRecording && "animate"}`}></Box>
        <Box className={`ripple ${isRecording && "animate"}`}></Box>
      </Box>
    </Box>
  );
};
