import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const TypingText = ({ markdownContent, isTyping }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(0);

  useEffect(() => {
    let timeoutId;
    const typeNextCharacter = () => {
      if (isTyping && visibleCharacters < markdownContent.length) {
        timeoutId = setTimeout(() => {
          setVisibleCharacters((prevVisibleChars) => prevVisibleChars + 50);
          typeNextCharacter();
        }, 1);
      }
    };

    typeNextCharacter();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [markdownContent, visibleCharacters, isTyping]);

  const typingText = markdownContent.substring(0, visibleCharacters);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      children={typingText}
      components={{
        a: (props) => (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        ),
      }}
    />
  );
};

export default TypingText;
