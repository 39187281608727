import { useEffect, useState } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div className="loadingSpinner">
      <div style={{ width: "125px", height: "125px", position: "relative" }}>
        {/* <img
src={logo}
alt=""
style={{
  width: "124px",
  height: "124px",
  position: "absolute",
}}
/> */}
        <CircularProgress
          size={100}
          // color={"#017101"}
          style={{ width: "inherit", height: "inherit" }}
        />
      </div>
    </div>
  );
};

const PublicContainer = ({ children, isSingup, tabTitle }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    try {
      await fetchUserAttributes();
      setIsLoading(false);
      navigate("/dashboard");
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!isSingup) {
      checkAuth();
    }
  }, []);

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  if (isLoading) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default PublicContainer;
