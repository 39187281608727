import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import IOSSwitch from "../Components/IOSSwitch";
import FileList from "../Components/Main/FileList";

const Categorization = () => {
  const [files, setFiles] = useState([null, null]);
  const [fileStatus, setFileStatus] = useState({});
  const [isGroupCol, setIsGroupCol] = useState(false);
  const [isBtnClick, setIsBtnClick] = useState(false);
  const [isCatType2, setIsCatType2] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.sub) {
      fetchStatus();
    }
  }, [user]);

  const fetchStatus = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/excel_fileStatus?userId=${user.sub}`
      )
      .then(({ data }) => {
        setFileStatus(data);
      });
  };

  const handleSubmit = async () => {
    try {
      if (files.length < 2) return toast.error("please chooses file");

      const formData = new FormData();
      formData.append("userId", user.sub);
      formData.append("isGroupCol", isGroupCol);
      formData.append("isCatType2", isCatType2);
      formData.append("catFile", files[0]);
      formData.append("tranFile", files[1]);
      axios.post(`${process.env.REACT_APP_API_URL}/api/ExcelCate`, formData);

      setTimeout(() => {
        fetchStatus();
      }, 2000);
      setIsBtnClick(true);
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };

  return (
    <Box
      sx={{
        m: 10,
      }}
    >
      <Box sx={{ position: "absolute", left: "40px", top: "80px" }}>
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/dashboard")}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <Typography textAlign="center">Category Excel File</Typography>
            <FileUpload isCat={true} files={files} setFiles={setFiles} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography textAlign="center">Transactions Excel File</Typography>
            <FileUpload isCat={false} files={files} setFiles={setFiles} />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ mr: 2 }}
              checked={isGroupCol}
              onChange={(e) => setIsGroupCol(e.target.checked)}
            />
          }
          label="doesn’t use the ‘material  group’"
          labelPlacement="end"
          sx={{ mt: 5 }}
        />
        <br />
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ mr: 2 }}
              checked={isCatType2}
              onChange={(e) => setIsCatType2(e.target.checked)}
            />
          }
          label="use the ‘Supplier categorization’"
          labelPlacement="end"
          sx={{ mt: 5 }}
        />
      </Box>
      <Box
        sx={{
          mt: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isBtnClick}
        >
          submit
        </Button>
      </Box>
      {Object.keys(fileStatus).length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              endIcon={<CachedIcon />}
              onClick={fetchStatus}
            >
              Fetch latest
            </Button>
          </Box>

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            <ListItem divider>
              <ListItemText
                sx={{ whiteSpace: "pre" }}
                primary={`No of transaction proceed: ${fileStatus.noTransactionDone} \t\t  status:- ${fileStatus.status}`}
              />
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Download proceed transaction" />
              <Button
                variant="contained"
                onClick={() => window.open(fileStatus?.url, "_blank").focus()}
              >
                Download
              </Button>
            </ListItem>
            <ListItem divider>
              <ListItemText primary={`Started at: - ${fileStatus.createdAt}`} />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary={`Last updated at: - ${fileStatus.updated_at}`}
              />
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
};

const FileUpload = ({ setFiles, isCat, files }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (isCat) {
        setFiles([acceptedFiles[0], files[1]]);
      } else {
        setFiles([files[0], acceptedFiles[0]]);
      }
    },
    [files]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
    },
  });

  return (
    <Box
      {...getRootProps({ className: "dropzone" })}
      sx={{
        "&:hover": {
          border: "1px solid rgba(0, 0, 0, 0.50)",
        },
        padding: { xs: "10px 0", sm: "15px 0", md: "20px 0" },
        cursor: "pointer",
        textAlign: "center",
      }}
      onClick={() => open()}
    >
      <input {...getInputProps()} />
      <Icon icon="tabler:upload" />
      <Typography mt={1}>
        Drag and drop or&nbsp;
        <span
          style={{
            color: "rgba(5, 87, 250, 1)",
            textDecorationLine: "underline",
          }}
        >
          Choose file
        </span>
        &nbsp; to upload
      </Typography>
      <aside>
        <ul
          style={{ paddingLeft: "0px", overflow: "auto", maxHeight: "140px" }}
        >
          {isCat && files[0] && (
            <FileList
              file={files[0]}
              setMyFilesState={async (file) => {
                setFiles(files.filter((item) => item.name !== file.name));
              }}
            />
          )}
          {!isCat && files[1] && (
            <FileList
              file={files[1]}
              setMyFilesState={async (file) => {
                setFiles(files.filter((item) => item.name !== file.name));
              }}
            />
          )}
        </ul>
      </aside>
    </Box>
  );
};

export default Categorization;
