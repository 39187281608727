import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { confirmSignUp, signIn } from "aws-amplify/auth";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import FormField from "../../assets/theme/overrides/FormField";
import { PrimaryButton } from "../../assets/theme/overrides/button";

const validationSchema = yup.object().shape({
  verificationCode: yup.string().required("Verification code is required"),
});

const initialValues = {
  verificationCode: "",
};
const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "20px",
  padding: "30px 20px",
  width: "100%",
  maxWidth: "500px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "260px",
    padding: "24px 12px",
  },
}));

export default function Verification() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    toast.success(
      "Please verify your self first, we have sent the verification link on your email"
    );
  }, [location?.state?.email]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = await confirmSignUp(
        location?.state?.email,
        values.verificationCode
      );
      if (data) {
        await signIn(location?.state?.email, location?.state?.password);
        setIsLoading(false);
        // navigate("/pricingPage");
        navigate("/dashboard");
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("verification error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Item elevation={4}>
          <Box>
            <Typography variant="h3" color="primary">
              {isLoading ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                "Verify your account"
              )}
            </Typography>
          </Box>
          <Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <TextField
                  id="email"
                  variant="outlined"
                  fullWidth
                  value={location?.state?.email}
                  size="small"
                  type="email"
                  disabled={true}
                  sx={{
                    marginTop: "20px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                />
                <FormField
                  name="verificationCode"
                  label="Verification code"
                  type="text"
                />
                <PrimaryButton
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  sx={{ width: "100%", mt: "25px" }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    "Verify"
                  )}
                </PrimaryButton>
              </Form>
            </Formik>
          </Box>
        </Item>
      </Grid>
    </>
  );
}
